

export default async (to, from, next) => {
  var token = localStorage.getItem("currencyToken") || '';
  var userInfo = localStorage.getItem("info") || '';


  try {
    userInfo = JSON.parse(userInfo);
  } catch (e) {
    userInfo = '';
  }

  // if (to.path.includes('manage')) {
  //   document.getElementById('chat-widget-container').style.display = 'block';

  // } else {
  //   document.getElementById('chat-widget-container').style.display = 'none';

  // }

  console.log(to);

  if (to.meta.requiresAuth) {
    if (token.length > 0) {
      if (userInfo.password2 == null) {
        next('/web/set-sec-password');
      }
      next();
    } else {
      localStorage.removeItem("currencyToken");
      next('/web/sessions/login');
    }

  } else {
    next();

  }
};

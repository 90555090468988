<template>
  <div id="app">
    <!-- <div class="gradient-bg">
      <div class="blur-1"></div>
      <div class="blur-2"></div>
      <div class="blur-3"></div>
    </div> -->
    <div v-if="$root.isLoading" class="loading">
      <div class="loading-box">

        <div class="loading-effect" style="top: 49%;left: 51%;">
          <div class="icon-button" :style="{ 'background-image': 'url(' + logo + ')' }"
            style="height: 3rem;width: 3rem;">
          </div>
        </div>
        <div class="loading-effect">
          <b-spinner style="width: 5rem; height: 5rem;" label="Large Spinner">

          </b-spinner>

        </div>
        <div class="loading-effect" style="top: 80%;">
          {{ $t('loading...') }}

        </div>
      </div>

    </div>


    <div class="d-flex">
      <div class="side_wrap">
        <div class="wrapper">
          <img src="./assets/images/logo/logo.png" alt="" height="auto" style="width:100%!important" class="logo my-4">
          <!-- <div class="font-weight-bold my-2">
            SMARTER CURRENCY EXCHANGE

          </div> -->

          <router-link tag="a" to="/web/exchange" class="nav-item-hold w-100">
            <b-button class="font-weight-bold text-14" variant="primary" block
              :class="[{ 'font-weight-bold': $route.path.includes('exchange') }]">
              {{ $t("exchange") }}
            </b-button>
          </router-link>
          <router-link tag="a" to="/web/homepage" class="nav-item-hold">
            <div class="nav-text text-gray"
              :class="[{ 'text-primary': $route.path.includes('home') }, { 'font-weight-bold': $route.path.includes('home') }]">
              <span class="text-18">
                <i class="fa-solid fa-house"></i>
              </span>
              <div class="">
                {{
      $t("home")
    }}

              </div>
            </div>
          </router-link>
          <router-link tag="a" to="/web/bonusRecord" class="nav-item-hold">
            <div class="nav-text text-gray"
              :class="[{ 'text-primary': $route.path.includes('bonusRecord') }, { 'font-weight-bold': $route.path.includes('bonusRecord') }]">
              <span class="text-18">
                <i class="fa-solid fa-gem"></i>
              </span>
              <div class="">
                {{
      $t("score")
    }}

              </div>
            </div>
          </router-link>
          <a href="/oanda/www.oanda.com/bvi-en/history.html" target="_blank" class="nav-item-hold">
            <div class="nav-text text-gray"
              :class="[{ 'text-primary': $route.path.includes('team') }, { 'font-weight-bold': $route.path.includes('team') }]">
              <span class="text-18">
                <i class="fa-solid fa-people-group"></i>
              </span>
              <div class="">
                {{
      $t("about_us")
    }}

              </div>
            </div>
          </a>
          <router-link tag="a" to="/web/manage" class="nav-item-hold">
            <div class="nav-text text-gray"
              :class="[{ 'text-primary': $route.path.includes('manage') }, { 'font-weight-bold': $route.path.includes('manage') }]">
              <span class="text-18">
                <i class="fa-solid fa-gear"></i>
              </span>
              <div class="">
                {{
      $t("manage")
    }}

              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="main-wrap">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Oanda Currency Exchange',
  data() {
    return {
      screenWidth: 0,
      logo: require('./assets/images/logo/logo.jpg')
    }
  },
  computed: {
    computedWidth: function () {
      return this.screenWidth + 'px';
    }
  },
  components: {
  },
  mounted() {
    this.screenWidth = screen.width;

  }
}
</script>

<style>
#app {
  font-family: 'Poppins-Medium' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
